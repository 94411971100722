var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mt-4" },
    [
      _c(
        "b-col",
        [
          _c(
            "b-card",
            { attrs: { header: "Contributors" } },
            [
              _c("contributor-list", {
                attrs: { holder: _vm.holder, holderId: _vm.holderId }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c(
            "b-card",
            { attrs: { header: "Tags" } },
            [
              _c("tag-list", {
                attrs: { holder: _vm.holder, holderId: _vm.holderId }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        [
          _c(
            "b-card",
            { attrs: { header: "Attributes" } },
            [
              _c("attribute-list", {
                attrs: {
                  holder: _vm.holder,
                  productDetailsId: _vm.productDetailsId
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }