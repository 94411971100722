<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <router-link v-if="albumId" :to="{ name: 'release-detail', params: { id: albumId } }">
          Keer terug naar de playlist...
        </router-link>

        <router-link v-if="trackSearch" :to="{ name: 'track-search', query: trackSearch }">
          Keer terug naar de track search...
        </router-link>

        <b-card header="Track" class="mt-4 track">

          <table class="table table-bordered">
            <tr>
              <th>Boep Identifier</th><td>{{ track.ProductDetails.ReportingCode }}</td>
              <th>Medley</th><td>{{ track.IsMedley|yesno }}</td>
            </tr>
            <tr>
              <th>ISRC</th><td>{{ track.ProductDetails.ISRC }}</td>
              <th>Losse verkoop</th><td>{{ track.SingleSaleAllowed|yesno }}</td>
            </tr>
            <tr>
              <th>Artiest</th><td>{{ track.ProductDetails.Attribution }}</td>
              <th>Compilatie Rechten</th><td>{{ track.ProductDetails.RecompilationRightsID }}</td>
            </tr>
            <tr>
              <th>Titel</th><td>{{ track.ProductDetails.Title }}</td>
              <th>Lengte</th><td>{{ track.Duration }}</td>
            </tr>
            <tr>
              <th>Versie Titel</th><td>{{ track.ProductDetails.VersionTitle }}</td>
              <th>BPM</th><td>{{ track.BPM }}</td>
            </tr>
            <tr>
              <th>C-Line</th><td>{{ track.CLine}}</td>
              <th>Geschreven Taal</th><td>{{ track.ProductDetails.TitleLanguageID|localeDescription }}</td>
            </tr>
            <tr>
              <th>P-Line</th><td>{{ track.PLine }}</td>
              <th>Gezongen Taal</th><td>{{ track.LyricsLanguageID|localeDescription }}</td>
            </tr>
            <tr>
              <th>Label</th><td>{{ track.Label }}</td>
              <th></th><td></td>
            </tr>
            <tr>
              <th>Verspreidingsgebied</th><td>{{ track.ProductWorkFlow.GlobalTerritory }}</td>
              <th></th><td></td>
            </tr>

            <tr v-if="track.Tags.TopLevel == 'Classical'">
              <th>Work title</th><td>{{ track.WorkTitle }}</td>
              <th>Key</th><td>{{ track.Key }}</td>
            </tr>
            <tr v-if="track.Tags.TopLevel == 'Classical'">
              <th>Movement Title</th><td>{{ track.MovementTitle }}</td>
              <th>Classical Catalog Nr</th><td>{{ track.ClassicalCatalogNr }}</td>
            </tr>
            <tr v-if="track.Tags.TopLevel == 'Classical'">
              <th></th><td></td>
              <th>Movement nr</th><td>{{ track.MovementNr }}</td>
            </tr>

            <tr>
              <td colspan="2" class="text-center">
                <template v-if="track.AssetFile.IsPresent == 1">
                  <b-button
                    v-if="audioFile == ''"
                    v-on:click="play()">
                    Play sample
                  </b-button>

                  <p v-if="audioPlayWarning">{{ audioPlayWarning }}</p>

                  <audio-player v-if="audioFile" :src="audioFile" />
                </template>
              </td>
              <td colspan="2" class="text-center">
                <template v-if="track.Security.ChangeAudio">
                  <p><strong>Wijzig audio:</strong></p>

                  <blob-upload v-on:input="audioReplacement = $event" class="mb-4" />

                  <template v-if="audioReplacement && audioReplacementStatus == null">
                    <b-progress v-if="audioReplacement.status == 'uploading'" :value="audioReplacement.uploadPercentage" :max="100" show-progress />

                    <template v-if="audioReplacement.status == 'uploadError'">
                      Er is iets misgegaan met het uploaden. Probeer het nog eens.
                    </template>
                    
                    <template v-if="audioReplacement.status == 'uploadComplete'">
                      <template v-if="audioReplacementValid == true">
                        <audio-player :src="audioReplacementSrc" />

                        <b-button class="mt-3" @click.prevent v-b-modal.confirm-audio-modal>Bevestig audio</b-button>
                      </template>

                      <template v-if="audioReplacementValid != true">
                        Er zijn problemen gevonden met het bestand.
                        <b-alert v-for="(msg, index) in audioReplacementValid" :key="index" variant="danger" class="mt-4" show>
                          {{ msg }}
                        </b-alert>
                      </template>
                    </template>
                  </template>

                  <b-modal id="confirm-audio-modal" title="Bevestig audio" ok-title="Bevestig" cancel-title="Annuleer" 
                            :ok-disabled="audioReplacementReason.length < 5" @ok="replaceAudio">
                    <page-snippet name="audio-replace" />
                    <b-form-textarea
                      id="textarea"
                      v-model="audioReplacementReason"
                      placeholder="Voer reden in..."
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-modal>

                  <template v-if="audioReplacement && audioReplacementStatus == 'progress'"> 
                    <b-spinner label="Spinning"></b-spinner>
                  </template>

                  <template v-if="audioReplacement && audioReplacementStatus == 'success'"> 
                    Audio wijziging is in behandeling. Het kan tot een uur duren voordat de nieuwe track afspeelt op de website.
                  </template>

                  <template v-if="audioReplacement && audioReplacementStatus == 'error'"> 
                    Er is iets misgegaan met het uploaden. Probeer het nog eens.
                  </template>
                </template>
              </td>
            </tr>
          </table>
        </b-card>
        
        <b-card header="Transaction Matches" class="mt-4" v-if="$root.$data.me.Username == 'erikbas'">
          <trx-match-list :track="track" />
        </b-card>

        <b-card header="Albums" class="mt-4">
          <release-list :filter="{ TrackMetadataID: track.TrackMetadataID }" />
        </b-card>

      </b-col>
    </b-row>

    <contributor-tags-attributes holder="Track" :holderId="track.TrackMetadataID" :productDetailsId="track.ProductDetails.ProductDetailsID" />

  </b-container>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue'
import ContributorTagsAttributes from '@/components/ContributorTagsAttributes.vue'

export default {
  name: "TrackDetail",
  components: {
    AudioPlayer,
    ContributorTagsAttributes,
    'blob-upload': () => import('@/components/BlobUpload.vue'),
    'release-list': () => import('@/components/ReleaseList.vue'),
    'page-snippet': () => import('@/components/PageSnippet'),
    'trx-match-list': () => import('@/components/TrxMatchList'),
  },
  data() {
    return {
      audioFile: '',
      audioReplacement: null,
      audioReplacementStatus: null,
      audioReplacementReason: '',
      audioReplacementSrc: null,
      trackSearch: null,
      audioPlayWarning: '',
    };
  },
  props: {
    track: Object,
    albumId: String,
  },
  computed: {
    audioReplacementValid () {
      if (this.audioReplacement && this.audioReplacement.status == 'uploadComplete') {
        return this.$validators.audioFile(this.audioReplacement)
      }
      return false
    }
  },
  watch: {
    audioReplacement(file) {
      this.audioReplacementStatus = null
      
      let reader = new FileReader();
      let self = this
      reader.onload = function(e) {
        self.audioReplacementSrc = e.target.result;
      }
      reader.readAsDataURL(file.rawFile);
    }
  },
  methods: {
    play () {
      let blockTracks = localStorage.getItem('blockPlayTrack')
      if (blockTracks != null) {
        blockTracks = JSON.parse(blockTracks)
        
        let diff = new Date() - new Date(blockTracks[this.track.TrackMetadataID])
        diff = diff/1000/60
        
        if (diff < 50) {
          console.log(diff)
          this.audioPlayWarning = 'Van deze track is de audio recent vervangen, het kan tot een uur duren voordat de nieuwe audio beschikbaar is.'
          return 
        }
      }

      this.audioFile = 'https://assets.the-source.eu/clip/' + this.track.ProductDetails.ReportingCode + '?refresh=' + new Date().getTime()
    },
    replaceAudio() {
      console.log("Replacing audio")
      this.audioReplacementStatus = 'progress'

      let blockTracks = localStorage.getItem('blockPlayTrack')
      if (blockTracks == null) {
        blockTracks = {}
      } else {
        blockTracks = JSON.parse(blockTracks)
      }
      blockTracks[this.track.TrackMetadataID] = new Date()
      localStorage.setItem('blockPlayTrack', JSON.stringify(blockTracks))

      this.$http.put('tracks/' + this.track.TrackMetadataID + '/replace-audio', 
      { 
        TemporaryBlobID: this.audioReplacement.sha,
        Reason: this.audioReplacementReason,
      }).then(() => {
        this.audioReplacementStatus = 'success'
      })
      .catch(() => {
        this.audioReplacementStatus = 'error'
      })
    },
  },
  mounted() {
    this.trackSearch = JSON.parse(localStorage.getItem('search-track-last'))

    this.$root.$data.crumbs = [
      { to: { name: "track-search", query: this.trackSearch }, text: "Tracks" },
      { text: this.track.ProductDetails.Title + ' - ' + this.track.ProductDetails.Attribution }
    ];

    /*
    this.$root.$data.crumbs = [
      { to: { name: "release-search" }, text: "Playlists" },
      {
        to: { name: "release-detail", params: { id: this.albumTrack.AlbumID } },
        text: this.albumTrack.Album.ProductDetails.Title + ' - ' + this.albumTrack.Album.ProductDetails.Attribution 
      },
      { text: this.track.ProductDetails.Title + ' - ' + this.track.ProductDetails.Attribution }
    ]; */

  }
};
</script>

<style lang="scss" scoped>
  .track {
    .card-header {
      color: black;
      background-color: #acbcc8;
    }
  }
</style>