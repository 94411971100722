<template>
    <b-row class="mt-4">
      <b-col>
        <b-card header="Contributors">
          <contributor-list :holder="holder" :holderId="holderId" />
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Tags">
          <tag-list :holder="holder" :holderId="holderId" />
        </b-card>
      </b-col>

      <b-col>
        <b-card header="Attributes">
          <attribute-list :holder="holder" :productDetailsId="productDetailsId" />
        </b-card>
      </b-col>
    </b-row>
</template>

<script>
  import TagList from '@/components/TagList.vue'
  import ContributorList from '@/components/ContributorList.vue'
  import AttributeList from '@/components/AttributeList.vue'

  export default {
    name: 'ContributorTagsAttributes',
    components: {
      TagList,
      ContributorList,
      AttributeList,
    },
    data () {
      return {
        loading: false,
      }
    },
    props: {
      holder: String,
      holderId: String,
      productDetailsId: String,
    },
    mounted () {
      this.loading = true
    }
  }
</script>

<style lang="scss" scoped>

</style>