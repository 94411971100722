var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-bordered" },
    _vm._l(_vm.roles, function(values, type) {
      return _c("tr", { key: type }, [
        _c("th", { staticClass: "text-nowrap" }, [
          _vm._v(_vm._s(_vm.roleDescriptions[type]))
        ]),
        _c(
          "td",
          [
            _c(
              "b-list-group",
              _vm._l(values, function(contributor, index) {
                return _c(
                  "b-list-group-item",
                  {
                    key: String(index) + "-" + contributor.ContributorID,
                    attrs: { variant: "secondary" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(contributor.Name1) +
                        " " +
                        _vm._s(contributor.Name2) +
                        " " +
                        _vm._s(contributor.Name3)
                    ),
                    _c("br"),
                    _vm._v(" " + _vm._s(contributor.RoleDetails) + " ")
                  ]
                )
              }),
              1
            )
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }