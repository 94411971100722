<template>
  <table class="table table-bordered">
    <tr v-for="(type, typeName) in types" :key="typeName">
      <th>
        <p>{{ type.Title }}</p>
      </th>
      <td>
        <b-list-group>
          <b-list-group-item v-for="(value, index) in type.values" :key="index" variant="secondary">
            {{ value }}
          </b-list-group-item>
        </b-list-group>
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    name: 'TagList',
    data () {
      return {
        loading: false,
        types: {},
      }
    },
    props: {
      holder: String,
      holderId: String,
    },
    mounted () {
      this.loading = true
      
      this.$http
        .get(this.holder.toLowerCase()+'s/'+this.holderId+'/tags')
        .then(response => {
            this.loading = false
            for (const [k,v] of Object.entries(response.data)) {
              
              let typeInfo = this.$root.$data.fieldDefs[this.holder]['Tags.'+k]
              if (typeInfo) {
                let item = {...typeInfo}
                item.values = v
                this.$set(this.types, k, item)
              }
            }
        }).catch(function (e) {
            console.log(e)
        })
    }
  }
</script>

<style lang="scss" scoped>

</style>