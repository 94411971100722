<template>
  <table class="table table-bordered">
    <tr v-for="(values, type) in roles" :key="type">
      <th class="text-nowrap">{{ roleDescriptions[type] }}</th>
      <td>
        <b-list-group>
          <b-list-group-item v-for="(contributor, index) in values" :key="String(index) + '-' + contributor.ContributorID" variant="secondary">
            {{ contributor.Name1 }} {{ contributor.Name2 }} {{ contributor.Name3 }}<br />
            {{ contributor.RoleDetails }}
          </b-list-group-item>
        </b-list-group>
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    name: 'ContributorList',
    data () {
      return {
        loading: false,
        roles: {},
        roleDescriptions: {},
      }
    },
    props: {
      holder: String,
      holderId: String,
    },
    mounted () {
      this.loading = true

      this.$root.$data.fieldDefs[this.holder]['Contributors.Role'].Options.forEach(role => {
        this.roleDescriptions[role.JsonValue] = role.Value
      })
      
      this.$http
        .get(this.holder.toLowerCase()+'s/'+this.holderId+'/contributors')
        .then(response => {
            this.loading = false
            this.roles = response.data    
        }).catch(function () {
            this.roles = []
        })
    }
  }
</script>

<style lang="scss" scoped>

</style>