var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-bordered" },
    _vm._l(_vm.types, function(type, typeName) {
      return _c("tr", { key: typeName }, [
        _c("th", [_c("p", [_vm._v(_vm._s(type.Title))])]),
        _c(
          "td",
          [
            _c(
              "b-list-group",
              _vm._l(type.values, function(value, index) {
                return _c(
                  "b-list-group-item",
                  { key: index, attrs: { variant: "secondary" } },
                  [_vm._v(" " + _vm._s(value) + " ")]
                )
              }),
              1
            )
          ],
          1
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }